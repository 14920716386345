<template>
   <v-app>
      <!-- <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
          </v-app-bar-title>
      </v-app-bar> -->
      <v-main >
        <v-container color="transparent">
          <v-row class="pt-10">
            <v-spacer/>
            <v-col>
          <v-card flat>
            <v-card-text class="text-center">
                  <v-img class="mx-auto" width="196" contain  :src="require('@/assets/chooseSite.png')"></v-img>
              <div class="pt-5 text-h4 font-weight-bold natural--text text--darken-4">
                เลือกหน่วยบริการ
              </div>
              <div class="pt-5 ">
                <Select :items="data.site" item-text="siteName" v-model="selectedSite" return-object/>
              </div>
              <v-btn height="54" block color="primary" @click="setTenant"><span class="text-title-2">ต่อไป</span></v-btn>
            </v-card-text>
          </v-card>
            </v-col>
            <v-spacer/>
          </v-row>
        </v-container>
          <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
   </v-app>
</template>

<script>
import Select from '@/components/common/Select'
export default {
  components: {
    Select
  },
  data () {
    return {
      data: '',
      loading: false,
      agree: false,
      selectedTenant: null
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      this.data = this.$offlineStorage.get("tenant")
      this.selectedSite =  this.data.site[0]
      this.loading = false
    },
    setTenant () {
      this.$offlineStorage.set("site",this.selectedSite);
      this.$router.replace({name: 'home'});
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
    border-radius: 8px;
}
</style>
